import React from "react";
import {Button, EnhancedTable, Paper, Text} from "@armus/armus-dashboard";
import Typography from "@mui/material/Typography";
import {getTypeLabel} from "../../components/utils";
import api from "../../../../lib/api";
import {Box, Grid} from "@mui/material";
import {Download1500Form} from "./StatusNew";
import {alpha} from "@mui/material/styles";

const StatusVerificationPending = (props) => {
    const {data, year, orgKey, implKey, onClose} = props;
    if (props.view === "verification") {
        return (
            <VerificationManager {...props} />
        );
    }
    return (
        <Paper p={4}  sx={{maxWidth: "75%", ml: "12.5%", mb: 3}}>

            <Typography variant={"h3"}>{getTypeLabel(data.entityType)} Verification Pending</Typography>
            <Typography variant={"subtitle1"} sx={{opacity: .5, mb: 2}}>
                Your submitting entity is pending verification. Please check back in 1-2 business days.
            </Typography>
            <Typography variant={"h3"} fontWeight={500} sx={{my: 2}}>TIN Information</Typography>
            <Box sx={{
                border: "1px solid #dddddd",
                background: alpha("#888888", .05),
                py: 2,
                px: 4,
                borderRadius: 4,
                textAlign: "left",
                mb: 2,
                overflow: "hidden"
            }}>
                {data.entityType === "group" && <h4>Group Name: {data.groupName}</h4>}
                <h4>TIN: {data.tin}</h4>
                Start Date: {data.tinDataBoundStartDate}<br/>
                End Date: {data.tinDataBoundEndDate}<br/>
            </Box>

            <Typography variant={"h3"} fontWeight={500} sx={{my: 2}}>NPI Information</Typography>
            <Box sx={{
                border: "1px solid #dddddd",
                background: alpha("#888888", .05),
                py: 2,
                px: 4,
                borderRadius: 4,
                textAlign: "left",
                mb: 2,
                overflow: "hidden"
            }}>
                {data.npiList.map((npi) => (
                    <Box sx={{borderBottom: "1px solid #dddddd", py: 2}}>
                        <h4>NPI: {npi.npi}</h4>
                        Name: {npi.name}<br/>
                    </Box>
                ))}
            </Box>

            <Download1500Form year={year} orgKey={orgKey} implKey={implKey} data={data} />
            <Box
                textAlign={"right"}
                mt={4}
            >
                <Button
                    color={"secondary"}
                    size={"large"}
                    onClick={onClose}
                >
                    Back
                </Button> &nbsp;
            </Box>
        </Paper>
    );
};
export default StatusVerificationPending;

const VerificationManager = (props) => {
    const {data, year, orgKey, implKey, onClose, onSave} = props;

    const [isLoading, setIsLoading] = React.useState(true);
    const [npiListInfo, setNpiListInfo] = React.useState([]);
    const [showRejectReason, setShowRejectReason] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const loadNpiInfo = async (npis = []) => {

        npis = npis.filter(it => (it || "").trim());
        if(npis.length === 0) {
            // nothing to do
            return;
        }
        setIsLoading(true);

        const res = await api.loadConsentCMSNPIData(npis);
        if(res.status === 200) {
            setNpiListInfo((data) => {
                return res.data.data;
            });
        }
        setIsLoading(false);
    };

    const handleApprove = async () => {
        setMessage("");
        setShowRejectReason(false);
        const saveData = {
            ...data,
            status: "VERIFIED"
        };
        const res = await onSave(saveData);
        if(res.status === 200) {
            onClose();
        }
        else {
            alert("Something unexpected happened, Please try again in a few moments.");
        }
    };

    const handleReject = async () => {
        setShowRejectReason(true);
        if(!message && showRejectReason) {
            alert("A rejection message is required.");
        }
        if(!!message && showRejectReason) {
            const saveData = {
                ...data,
                verificationMessage: message,
                status: "INVALID"
            };
            await onSave(saveData);
            onClose();
        }
    };

    const handleCancel = () => {
        setShowRejectReason(false);
    };

    React.useEffect(() => {
        loadNpiInfo(data.npiList.map(it => it.npi));
    }, [data.npiList]);

    const columns = [
        {
            id: "index",
            label: "",
            isSortable: false,
            props: {width: "1%", align: "left", sx: { p: 2, pt: 2.65, verticalAlign: "top"}},
            Content: ({row, y}) => (<Box color={row.error ? 'error.main' : undefined}>
                {y + 1}.
            </Box>)
        },
        {
            id: "npi",
            label: "NPI",
            isSortable: false,
            props: {width: "100%", align: "left", sx: { py: 2, verticalAlign: "top"}},
            Content: ({row}) => {
                return (
                    <Box color={row.error ? 'error.main' : undefined}>
                        <h3 style={{color: "inherit"}}>{[row.firstName || "N/A", row.middleName, row.lastName].join(" ")}</h3>
                        <Box>
                            NPI: <strong>{row.npi}</strong>&nbsp;<br/>
                            {row.error ? row.error.message : null}
                        </Box>
                        {!!row?.organizations && <Box py={2}>
                            <h4>Organizations</h4>
                            {(row?.organizations || []).map( (org, index) => {
                                const scenario = data.entityType === "group" ? org?.groupScenario : org?.individualScenario;
                                let isEligible = false;
                                let isOptedIn = false;

                                if(typeof scenario === "object") {
                                    isEligible = data.entityType === "group" ? org?.groupScenario?.isEligible?.group : org?.individualScenario?.isEligible?.individual;
                                    isOptedIn = data.entityType === "group" ? org?.groupScenario?.isOptedIn : org?.individualScenario?.isOptedIn;
                                }
                                return (
                                    <Box key={index} sx={{borderTop: "1px solid #eeeeee", py: 1}}>
                                        <Grid container spacing={2}>
                                            <Grid xs={10} item>
                                                <h4>{org.prvdrOrgName}</h4>
                                                {org.addressLineOne}<br/>
                                                {org.addressLineTwo}<br/>
                                                {org.city}, {org.state} {org.zip}<br/>
                                            </Grid>
                                            {typeof scenario === "object" ? (
                                                <Grid
                                                    xs={2}
                                                    item
                                                    color={'info.main'}
                                                    fontWeight={600}
                                                >
                                                    {getTypeLabel(data.entityType)} Is Eligible: {isEligible ? "YES" : "NO"}<br />
                                                    {getTypeLabel(data.entityType)} Is Opted In: {isOptedIn ? "YES" : "NO"}
                                                </Grid>
                                            ) : (
                                                <Grid
                                                    xs={2}
                                                    item
                                                    color={'default.main'}
                                                    fontWeight={600}
                                                >
                                                    {getTypeLabel(data.entityType)} Is Eligible: N/A<br />
                                                    {getTypeLabel(data.entityType)} Is Opted In: N/A
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                );
                            })}
                        </Box>}
                    </Box>
                );
            }
        }
        // {
        //     id: "index",
        //     label: "Eligible Clinician",
        //     isSortable: false,
        //     props: {width: "1%", align: "center", sx: { p: 2, pt: 2.65, verticalAlign: "top"}},
        //     Content: ({row, y}) => (<Box color={row.error || !row.amsMipsEligibleClinician ? 'error.main' : 'success.main'} fontWeight={600}>
        //         {row.amsMipsEligibleClinician ? "YES" : "NO"}
        //     </Box>)
        // },
    ];

    const canReject = !!message;

    return (
        <Paper p={4} sx={{maxWidth: "75%", ml: "12.5%", mb: 3}}>
            <Typography variant={"h3"}>Verify {getTypeLabel(data.entityType)} Supporting Documents</Typography>
            <Typography variant={"subtitle1"} sx={{opacity: .5, mb: 2}}>
                Please review the {getTypeLabel(data.entityType)} TIN, NPI(s) and supporting 1500Form.pdf
            </Typography>
            <Typography variant={"h3"} fontWeight={500} sx={{my: 2}}>TIN Information</Typography>
            <Box sx={{
                border: "1px solid #dddddd",
                background: alpha("#888888", .05),
                py: 2,
                px: 4,
                borderRadius: 4,
                textAlign: "left",
                mb: 2,
                overflow: "hidden"
            }}>
                {data.entityType === "group" && <h4>Group Name: {data.groupName}</h4>}
                <h4>TIN: {data.tin}</h4>
                Start Date: {data.tinDataBoundStartDate}<br/>
                End Date: {data.tinDataBoundEndDate}<br/>
            </Box>

            <Typography variant={"h3"} fontWeight={500} sx={{my: 2}}>CMS NPI Information</Typography>
            <EnhancedTable
                isLoading={isLoading}
                rows={npiListInfo}
                columns={columns}
                dense={true}

                sx={{
                    border: "1px solid #dddddd",
                    background: alpha("#888888", .05),
                    padding: 0,
                    borderRadius: 4,
                    textAlign: "center",
                    mb: 2,
                    overflow: "hidden"
                }}
            />

            <Download1500Form year={year} orgKey={orgKey} implKey={implKey} data={data} />

            <Box textAlign={"right"} mt={4}>
                {showRejectReason ? <>
                    <Typography textAlign={"left"}>Please provide a reason for rejecting this application.</Typography>
                    <Text
                        label={"Verification Message"}
                        value={message}
                        onChange={(e, v, d) => setMessage(v)}
                        required={true}
                        multiline={true}
                        rows={4}
                        sx={{mb: 2}}
                    />
                    <Button
                        color={"secondary"}
                        size={"large"}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button> &nbsp;
                    <Button
                        color={"error"}
                        size={"large"}
                        onClick={handleReject}
                        disabled={!canReject}
                    >
                        Submit Rejection
                    </Button>
                </> : <>
                    <Button
                        color={"secondary"}
                        size={"large"}
                        onClick={onClose}
                    >
                        Cancel
                    </Button> &nbsp;
                    <Button
                        color={"error"}
                        size={"large"}
                        onClick={handleReject}
                    >
                        Reject
                    </Button> &nbsp;
                    <Button
                        color={"primary"}
                        size={"large"}
                        onClick={handleApprove}
                    >
                        Approve
                    </Button>
                </>}
            </Box>
        </Paper>
    );
};
